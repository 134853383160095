<template>
	<div class="widthFull h-100">
		<loading v-if="loadingState" />

		<div class="container-fluid">
			<div class="row pt-5 mt-0 mt-lg-5 pb-3 center">
				<h1 class="text-red widthFull center Medium pt-5">
					<b>OPS!</b>
				</h1>

			</div>
		</div>

		<div class="container center pb-5 mb-5">

			<h3 class=" center glade">PAGINA NON TROVATA! </h3>

			<h5 class=" center mt-5 pb-5 Regular">LA PAGINA CHE STAI CERCANDO NON È DISPONIBILE.</h5>

			<router-link
				class="btn btn-lg btn-primary px-5"
				to="/"
			>Home</router-link>

		</div>

	</div>
</template>


<script>
	import { mapGetters, mapState } from 'vuex';
	import axios from 'axios';
	import { USER_REQUEST } from 'actions/user';

	export default {
		name: 'PageNotFound',
		components: {},
		data() {
			return {
				loadingState: '',
				userToken: '',
			};
		},
		methods: {},
		watch: {},
		mounted: function () {
			var _vue = this;
		},
		computed: {
			...mapGetters([
				'getProfile',
				'isAuthenticated',
				'isProfileLoaded',
				'acceptCookies',
				'checkAge',
				'acceptSalesForce',
				'acceptRelay42',
			]),
			...mapState({ profile: (state) => state.user.profile }),
		},
	};
</script>

<style lang="css" scoped>
	.fitAvailableHeight {
		min-height: -webkit-fill-available;
	}
</style>
