
<template>
	<div class="center-container">
		<slot />
	</div>
</template>

<style scoped>
</style>


<script>
	export default {
		name: 'center-container',
	};
</script>
