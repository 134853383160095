<template>

	<footer class="container-fluid Medium ">

		<div class="row  footer__bottom justify-content-center">
			<small class="col-12 col-md-6 col-lg-2 text-center text-lg-left  pt-md-4 pt-lg-0 black">
				<b>Copyright © 2021 Coca-Cola HBC. <br> All rights reserved. <br>
					Coca-Cola HBC Italia S.r.l., con unico socio </b>
				<br> Piazza Indro Montanelli 30 <br> 20099 Sesto San Giovanni (MI)</small>

			<small class="col-12 col-md-6 col-lg-2 text-center text-lg-left pt-2 pt-md-4 pt-lg-0">Capitale sociale: 172.000.000,00 <br> Partita Iva e CF: 12363410155 <br> Numero REA: MI-1551603 <br> PEC: cchbci@legalmail.com</small>
			<div class="col-12 col-lg-2 text-center pt-2 pt-md-4 pt-lg-0">
				<img
					src="../../assets/images/logo.png"
					alt=""
					class="img-fluid logo"
					ref="mainNavLogo"
				/>
			</div>
			<small class="col-12 col-lg-2 text-center text-lg-right pt-2 pt-md-4 pt-lg-0 ">
				<a
					:href="terminiUso"
					target="_blank"
					class="  "
				>Termini e condizioni d'uso</a> <br> <a
					:href="privacyPolicy"
					target="_blank"
					class=""
				>Informativa sulla privacy</a> <br> <a
					:href="cookiePolicy"
					target="_blank"
				>Cookie</a> </small>

		</div>

	</footer>
</template>

<script>
	import { mapGetters, mapState } from 'vuex';
	import { sharedEnvMixin } from '../../mixins/sharedEnvMixin';
	const moment = require('moment');

	export default {
		name: 'sqreen-footer',
		mixins: [sharedEnvMixin],
		data() {
			return {
				moment: moment,
				year: moment().year(),
			};
		},
		computed: {
			...mapGetters([
				'getProfile',
				'loginerror',
				'loadingState',
				'isAuthenticated',
				'isProfileLoaded',
				'authStatus',
				'checkAge',
			]),
			...mapState({
				//response: state => `${state.status.response.data}`,
			}),
		},
	};
</script>

<style lang="css" scoped>
</style>
