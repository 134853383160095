 <template>

	<div class="widthFull spotBack min-height-calc">
		<div class="track"></div>

		<div class="row justify-content-center">

			<div class="col-12 ">
				<div class="row  center notActiveTopBg">

				</div>
			</div>

		</div>

		<div class="row justify-content-center mb-5">

			<h4 class="black Bold center widthFull  pt-5">

				Torna a trovarci tra:

			</h4>

			<div class="col-md-12 pb-2 mb-5 pt-2 center orange_bg">

				<countdown :time="countdown">

					<template slot-scope="props">

						<div class="row center justify-content-center pb-5 pt-1">

							<div class="inlineTimeElements mx-1 mx-md-4 text-center relative">

								<div class="timeElement darkBlue BoldFont  white"> {{ props.days }}

								</div>
								<p
									v-if="props.days == 1"
									class="Regular white mt-2 mb-0"
								>Giorno</p>
								<p
									v-else
									class="Regular white mt-2 mb-0"
								>Giorni</p>

							</div>

							<div class="inlineTimeElements  mx-1 mx-md-4  text-center relative">

								<div class="timeElement darkBlue BoldFont  white "> {{ props.hours }}

								</div>
								<p
									v-if="props.hours == 1"
									class="Regular white mt-2 mb-0"
								>Ora</p>
								<p
									v-else
									class="Regular white mt-2 mb-0"
								>Ore</p>

							</div>

							<div class="inlineTimeElements  mx-1 mx-md-4 text-center relative">

								<div class="timeElement darkBlue BoldFont  white"> {{ props.minutes }}
								</div>
								<p
									v-if="props.minutes == 1"
									class="Regular white mt-2 mb-0"
								>Minuto</p>
								<p
									v-else
									class="Regular white mt-2 mb-0"
								>Minuti</p>

							</div>

							<div class="inlineTimeElements  mx-1 mx-md-4 text-center relative">

								<div class="timeElement darkBlue BoldFont  white"> {{ props.seconds }}

								</div>
								<p
									v-if="props.seconds == 1"
									class="Regular white mt-2 mb-0"
								>Secondo</p>
								<p
									v-else
									class="Regular white mt-2 mb-0"
								>Secondi</p>

							</div>

						</div>

					</template>

				</countdown>

			</div>

		</div>

		<div class="col-12  ">
			<div class="row  greyLine ">

			</div>
		</div>

	</div>

</template>

<script>
	const moment = require('moment');
	export default {
		name: 'NotActive',
		data() {
			return {
				time: false,
				countdown: 0,
				datenow: '',
			};
		},
		methods: {
			timeReactive() {
				var self = this;
				this.datenow = moment();

				if (moment() >= moment(process.env.VUE_APP_START)) {
					window.location.replace('/');
				}
			},
		},
		created: function () {
			//// COUNDOWN
			//var data = '2019-09-09 00:00:00'

			setInterval(this.timeReactive, 1000);

			var now = moment();

			var then = moment(process.env.VUE_APP_START);

			var dif = then.diff(now);

			//moment.utc(moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(then,"DD/MM/YYYY HH:mm:ss"))).format("HH:mm:ss")
			this.countdown = dif;
		},
	};
</script>

<style lang="scss" scoped>
</style>
