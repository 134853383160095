<template>
	<div id="app">
		<div class="main">
			<navigation v-if="$route.path != '/check-age'" />
			<navigation v-if="$route.path == '/check-age' && popUpCheckAge" />
			<div class="main-container">
				<router-view />
			</div>
		</div>
		<sqreen-footer />
	</div>

</template>

<style lang="css" scoped>
	/* @import '~bootstrap/dist/css/bootstrap.min.css'; */
	/* @import './assets/css/main.css'; */
</style>

<script>
	import Navigation from 'components/navigation';
	import { USER_REQUEST } from 'actions/user';
	import SqreenFooter from './components/footer/index.vue';
	import './assets/scss/main.scss';

	export default {
		components: {
			SqreenFooter,
			Navigation,
			//loading,
		},
		data() {
			return {};
		},
		name: 'app',
		created: function () {
			if (this.$store.getters.isAuthenticated) {
				this.$store.dispatch(USER_REQUEST);
			}
		},
		watch: {
			$route: function () {},
		},
	};
</script> 
