
<template>
	<div class="widthFull  pb-5">

		<div class="mt-0">
			<Header />
		</div>

		<Active class="mt-2 mt-md-5" />

		<NotActive class="mt-2 mt-md-5 pt-2" />

		<!-- <HowParticipate id="sei-pronto" /> -->

		<CookieBanner />

	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import { FROM_SET } from 'actions/auth';

	import Header from './header.vue';

	import CookieBanner from './cookie-banner.vue';
	import Active from './active.vue';
	import NotActive from './notActive.vue';

	export default {
		components: {
			Header,

			CookieBanner,
			Active,
			NotActive,
		},
		name: 'home',
		computed: {
			...mapGetters(['isAuthenticated', 'authStatus', 'getFrom']),
		},
		methods: {},

		created: function () {},
		mounted: function () {},
	};
</script>

<style lang="css" scoped>
	.shadowTrade {
		box-shadow: 0px 0px 20px 6px #0000007a;
		border-top: 2px solid #969696;
		border-bottom: 2px solid #969696;
		margin-top: 50px;
	}
</style>
