// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// IE EXPLORER NEEDS
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////───────────────▄▄───▐█/////////////////////////////////////////////////////
///////////////////////////////////////───▄▄▄───▄██▄──█▀───█─▄////////////////////////////////////////////////////
///////////////////////////////////////─▄██▀█▌─██▄▄──▐█▀▄─▐█▀/////////////////////////////////////////////////////
///////////////////////////////////////▐█▀▀▌───▄▀▌─▌─█─▌──▌─▌/////////////////////////////////////////////////////
///////////////////////////////////////▌▀▄─▐──▀▄─▐▄─▐▄▐▄─▐▄─▐▄////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import "@babel/polyfill";
import 'bootstrap'
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import Loading from 'components/lib/loading'
import CenterContainer from 'components/lib/center-container'
/* import '@fortawesome/fontawesome-free/css/all.css' */
import {
  library
} from '@fortawesome/fontawesome-svg-core'
import {
  faSpinner,
  faTimesCircle,
  faUser,
  faCheck,
  faSearchPlus,
  faEye,
  faPlusCircle,
  faFileAlt,
  faCaretSquareDown,
  faLaptop,
  faInfoCircle,
  faTrashAlt,
  faCheckCircle,
  faFileInvoice,
  faReceipt,
  faWineBottle,
  faCamera,
  faShippingFast,
  faGlobe,
  faShoppingCart,
  faFileImage,
  faArrowRight,
  faArrowLeft,
  faArrowAltCircleUp

} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faYoutube
} from '@fortawesome/fontawesome-free-brands'
import {
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
import vueHeadful from 'vue-headful'
import VeeValidator from './utils/vee-validate'
import VueSweetalert2 from 'vue-sweetalert2'
import VueScrollTo from 'vue-scrollto'
import VueMoment from 'vue-moment'
import Carousel3d from 'vue-carousel-3d'
import VueCountdown from '@chenfengyuan/vue-countdown'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import VueObserveVisibility from 'vue-observe-visibility'
import VueGtm from 'vue-gtm'
import VueSocialSharing from 'vue-social-sharing'
import {
  VueReCaptcha
} from 'vue-recaptcha-v3'
import CryptoJS from 'crypto-js'
import VueGtagAll from "vue-gtag";
import VueGtag from 'vue-gtag-conversion'
import Ads from 'vue-google-adsense'
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////CRYPT DATA///////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
Vue.prototype.$encryptByAES = function (string, key) {
  if (process.env.VUE_APP_CRYPT == "ON") {
    let ckey = CryptoJS.enc.Utf8.parse(key);
    let encrypted = CryptoJS.AES.encrypt(string, ckey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.ciphertext.toString(); // return ciphertext in hex format
  } else {
    return JSON.parse(string)
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////DECRYPT DATA/////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
Vue.prototype.$decryptByAES = function (string, key) {
  if (process.env.VUE_APP_CRYPT == "ON") {
    let ckey = CryptoJS.enc.Utf8.parse(key);
    var ciphertext = CryptoJS.enc.Hex.parse(string);
    let srcs = CryptoJS.enc.Base64.stringify(ciphertext);
    let decrypt = CryptoJS.AES.decrypt(srcs, ckey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    let decrypted = decrypt.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypted)
  } else {
    return string
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////LOG CALL DATA////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
Vue.prototype.$callLog = function (string, data) {
  if (process.env.VUE_APP_CRYPT != 'ON') {
    console.log(string + '-> call', {
      data
    })
  }
  return true
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////LOG RESP DATA////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
Vue.prototype.$respLog = function (string, data) {
  if (process.env.VUE_APP_CRYPT != 'ON') {
    console.warn(string + '-> resp', {
      data
    })
  }
  return true
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////LOG TRACK DATA///////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
Vue.prototype.$track = function (string, data) {
  if (process.env.VUE_APP_CRYPT != 'ON') {
    console.log(string + ' -> ', data)
  }
  return true
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////GOOGLE TAG MANAGER////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
if (process.env.VUE_APP_GTMSTATUS == "on") {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM, // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
    defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    vueRouter: router,
  });
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////GOOGLE ADSENSE/////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
if (process.env.VUE_APP_GTAGAWSTATUS == 'on' && process.env.VUE_APP_GTAGSTATUS == 'off') {

  Vue.use(Ads.AutoAdsense, {
    adClient: process.env.VUE_APP_GTAGAW
  })
  Vue.use(VueGtag, {
    id: process.env.VUE_APP_GTAGAW, // Your Adwords ID
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: process.env.VUE_APP_NODE_ENV !== 'production' (optional)
    debug: true, // Whether or not display console log debugs (optional)
  });
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////VUE GTAG IF ADSENS IS ON GTAG == ADSENSE///////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
if (process.env.VUE_APP_GTAGSTATUS == 'on' && process.env.VUE_APP_GTAGAWSTATUS == 'off') {

  Vue.use(VueGtagAll, {
    config: {
      id: process.env.VUE_APP_GTAG
    }
  }, router);
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/*****************************************************************/
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////GENERAL///////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
require('intersection-observer');
require('vue2-animate/dist/vue2-animate.min.css')
Vue.use(VueObserveVisibility)
Vue.use(Viewer)
//Vue.component('add-this', AddThis)
Vue.component(VueCountdown.name, VueCountdown);
Vue.use(VueSocialSharing);
Vue.use(Carousel3d);
Vue.use(VueMoment);
// Scroll TO
Vue.use(VueScrollTo);
// SWEET ALERT 2
Vue.use(VueSweetalert2);
// HEAD DATA PLUGIN
Vue.component('vue-headful', vueHeadful);
// FONT AWEASOME PLUGIN
Vue.component('font-awesome-icon', FontAwesomeIcon)
// LOADSCREEN
Vue.component('loading', Loading)
// CENTER CONTAINER
Vue.component('center-container', CenterContainer)
Vue.config.productionTip = false
// ADD AWEASOME ICOMS:
library.add(faSpinner, faFacebookF, faCheck, faFileAlt, faSearchPlus, faEye, faCaretSquareDown, faUser, faLaptop, faInstagram, faYoutube, faTwitter, faInfoCircle, faPlusCircle, faTimesCircle, faTrashAlt, faCheckCircle, faFileInvoice, faReceipt, faWineBottle, faCamera, faShippingFast, faGlobe, faShoppingCart, faFileImage, faArrowRight, faArrowLeft, faArrowAltCircleUp)
Vue.use(VeeValidator.core, VeeValidator.config);
//Vue.config.devtools = true;
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  Loading,
  template: '<App/>',
  components: {
    App
  },
})
